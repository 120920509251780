import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ThisReceiver } from '@angular/compiler';
import { SellerService } from 'app/core/seller/seller.service';
import { UserService } from 'app/core/user/user.service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent  implements OnInit {
p: any;
userDetails: any= {};
address: any= {};
orderid: '';
orderDetails: any= null;
orderTransactions = [];
originalorderstatus:string = '';
ordersdata: AngularFireObject<any>;
modalRef: BsModalRef;
datePickerConfig: Partial<BsDatepickerConfig>;
etatime: Date = new Date();
isAdmin = false;
etadate = new Date();
  
  constructor(private route: ActivatedRoute,  public router: Router, public af: AngularFireDatabase, public toastr: ToastrService, private sellerservice: SellerService, private modalService: BsModalService, public userService: UserService) {
      this.route.params.pipe(map(params => params['id'])).subscribe((Id) => {
      this.isAdmin = this.userService.isAdmin();
      if (Id != null) {
        this.orderid = Id;
        this.ordersdata = this.af.object('/suborders/' + Id);
        this.ordersdata.valueChanges().subscribe(
          (response) => {
            console.log(response);
            this.orderDetails = response;
            this.orderDetails.deliverybyname = (this.orderDetails.deliveryby == 'vegecious') ?  'Vegecious' : 'seller';
            this.orderDetails.sellerInfo = {};
            this.sellerservice.getSellerById(this.orderDetails.sellerId).then((sellerinfo) => {
              this.orderDetails.sellerInfo = sellerinfo;
              this.orderDetails.deliverybyname = this.orderDetails.sellerInfo.storeName;
            });
            this.orderDetails.paymentMethod = this.orderDetails.paymentMethod || 'RazorPay';            
            this.originalorderstatus = this.orderDetails.status;
            this.orderDetails.sellersettlement = this.orderDetails.sellersettlement || {
              amountpaid: 0
            };
            this.userDetails = response.buyerDetails;
            this.address = response.deliverydetails || {
              address: '',
              city: '',
              zipcode: ''
            };
          }
        );

        this.af.object('/suborderslog/' + Id).valueChanges().subscribe((_transactions: any) => {
          if (_transactions != null) {
            Object.keys(_transactions).forEach((_key) => {
              this.orderTransactions.push({
                key: parseInt(_key, 10),
                utctime: new Date(parseInt(_key, 10)).toISOString(),
                msg: _transactions[_key]
              });            
            });
          } else {
            this.orderTransactions = [];
          }
        });
      }
    });
  }

  ngOnInit() {
    this.datePickerConfig = {
      containerClass: 'theme-default',
      showWeekNumbers: false
    };
  }

  CODConfirmBtnDisabled = true;
  codtermschanged(codtermscheckbox) {
    console.log('Cod terms changed');
    this.CODConfirmBtnDisabled = !codtermscheckbox.currentTarget.checked;    
  }

  OnChangeStatus(template: TemplateRef<any>, codtemplate: TemplateRef<any>, event: any) {
    const oldstatus = this.originalorderstatus;
    const newstatus = event.target.value;
    if ((oldstatus == 'Pending') && (newstatus == 'Accepted')) {
      this.orderDetails.status = newstatus;
      if (this.orderDetails.paymentMethod == "COD") {
        this.CODConfirmBtnDisabled = true;
        this.modalRef = this.modalService.show(codtemplate);
      } else {
        this.modalRef = this.modalService.show(template);
      }
      
    } else if (newstatus == "Canceled") {
      const cancelReason = '';
      Swal.fire({
        title: 'Order Cancel?',
        text: 'Order is being cancelled.',
        icon: 'warning',
        input: 'text',
        inputLabel: 'Provide a reason for order cancellation',
        inputValue: cancelReason,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No, do not cancel order!',
        inputValidator: (value) => {
          console.log('Validator');
          if ((!value) || (value.length < 5)) {
            return 'You need to provide reason!'
          }
        },
        preConfirm: function() {
          return new Promise<void>(function(resolve) {
            setTimeout(function() {
              resolve()
            }, 2000)
          })
        }
      }).then((result: any) => {
          if (result.value) {
            this.ordersdata.update({status: event.target.value, cancelreason: result.value}).then((res) => {
              this.toastr.success('Order status updated!', 'Success!');
            }).catch((err) => {
              this.toastr.error('Error updating Order status. Err=' + err.toString(), 'Error!');
            })
          }
          else if (result.dismiss === Swal.DismissReason.cancel) {
            // reset the drop down to original value.
            this.orderDetails.status = this.originalorderstatus;
          }
      });
    } else {
      Swal.fire({
        title: 'Update Order Status?',
        text: 'Are you sure you want to update order status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, update it!',
        cancelButtonText: 'No, cancel!',
        preConfirm: function() {
          return new Promise<void>(function(resolve) {
            setTimeout(function() {
              resolve()
            }, 2000)
          })
        }
      }).then((result: any) => {
          if (result.value) {
            this.ordersdata.update({status: event.target.value}).then((res) => {
              this.toastr.success('Order status updated!', 'Success!');
            }).catch((err) => {
              this.toastr.error('Error updating Order status. Err=' + err.toString(), 'Error!');
            })
          }
          else if (result.dismiss === Swal.DismissReason.cancel) {
            // reset the drop down to original value.
            this.orderDetails.status = this.originalorderstatus;
          }
      });
    }
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    let eta = this.etadate;
    eta.setHours(this.etatime.getHours());
    eta.setMinutes(this.etatime.getMinutes());
    eta.setSeconds(0);
    
    const etautc = new Date(eta.toDateString() + ' ' + eta.getHours() + ':' + eta.getMinutes() + ' GMT+530');



    this.orderDetails.deliverydetails.etautc = etautc.toISOString();

    this.ordersdata.update({status: 'Accepted', deliverydetails: this.orderDetails.deliverydetails}).then((res) => {
      this.toastr.success('Order status updated!', 'Success!');
    }).catch((err) => {
      this.toastr.error('Error updating Order status. Err=' + err.toString(), 'Error!');
    })
    this.modalRef.hide();
  }
 
  decline(): void {
    //this.message = 'Declined!';
    this.orderDetails.status = this.originalorderstatus;
    this.modalRef.hide();
  }
}
