import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

export interface Tag {
    id: string;
    name: string;
}

export interface Location {
    title: string;
}


@Injectable()
export class SellerService {
    sellerinfo: any;
    constructor(public af: AngularFireDatabase, private functions: AngularFireFunctions) {
        this.sellerinfo = null;
    }

    getSeller() {
        return this.sellerinfo;
    }


    getSellerById(id: string): Promise<any> {
        console.log('Getting seller id: ' + id);
        const p = new Promise((resolve, reject) => {
            this.af.list('/business', ref => ref.orderByChild('sellerId').equalTo(id).limitToFirst(1)).snapshotChanges().subscribe((businessdataresponse) => {
                const _businessdatas = [];
                businessdataresponse.forEach((_o: any) => {
                    const _businessdata = _o.payload.toJSON();
                    _businessdata.$key = _o.key;
                    _businessdatas.push(_businessdata);
                })
                let sellerinfo = _businessdatas[0] || {
                    $key: '',
                    storeName: '',
                    email: '',
                    description: '',
                    address: '',
                    officeLocation: '',
                    phoneNo: '',
                    locationsserved: ''
                };
                resolve(sellerinfo);
            });
        });

        return p;
    }


    getSelleroOnAuth(): Promise<any> {
        return this.sellerinfo;
    }
    setSeller(seller: any): Promise<any> {
        console.log('Seller set as : ' + seller);
        const p = new Promise((resolve, reject) => {
            this.sellerinfo = seller;
            this.af.list('/business', ref => ref.orderByChild('sellerId').equalTo(seller.uid).limitToFirst(1)).valueChanges().subscribe((res) => {
                this.sellerinfo.business = res[0] || {
                    storeName: '',
                    email: '',
                    description: '',
                    address: '',
                    officeLocation: '',
                    phoneNo: '',
                    locationsserved: ''
                };
                resolve(this.sellerinfo);
            });
        });

        return p;
    }

    removeSeller() {
        this.sellerinfo = null;
    }

    getMockTags() {
        return '#Jain,#Vegan,#IndoChinese,#Gujarati,#Swaminarayan,#Punjabi'.split(',');
    }


    tags: Observable<any[]>;
    getTags(term: string = null): Observable<any[]> {
        this.tags = this.af.list('/tags', ref => ref.orderByChild('tags')).snapshotChanges()
                            .pipe(map(actions => actions.map(this.documentToDomainObject)))
        /*if (term) {
            items = items.filter(x => x.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }*/
        return this.tags
    }

    promorules: string[] = ["IsNotExpired", "AllowOnlyMaxUses", "IsValidForSeller"];
    getPromoRules(): string[] {
        /*this.promorules = this.af.list('/promorules', ref => ref.orderByChild('rules')).snapshotChanges()
                            .pipe(map(actions => actions.map(this.documentToDomainObject)))*/
        return this.promorules;
    }

    
    promodiscountlogics: string[] = ["DiscountPctToGrandTotal", "DiscountAmtToGrandTotal"];
    getPromoDiscountLogics(): string[] {
        /*this.promorules = this.af.list('/promorules', ref => ref.orderByChild('rules')).snapshotChanges()
                            .pipe(map(actions => actions.map(this.documentToDomainObject)))*/
        return this.promodiscountlogics;
    }

    locations: Observable<any[]>;
    
    documentToDomainObject = _ => {
        const tmpobject = _.payload.val();
        const object = {
            text: tmpobject.tags,
            id: _.payload.key
        }
        console.log(JSON.stringify(object));
        return object;
    }
    

    documentToLocationObject = _ => {
        const tmpobject = _.payload.val();
        const object = {
            text: tmpobject.title,
            id: _.payload.key
        }
        console.log(JSON.stringify(object));
        return object;
    }


    allsellers: any[] = null;
    getAllSellers(): Promise<any>  {
        const p = new Promise((resolve, reject) => {
            if (this.allsellers == null) {
                console.log('get all sellers');
                this.af.list('/business').snapshotChanges().subscribe((allsellersresponse: any[]) => {
                    const _sellers = [];
                    allsellersresponse.forEach((_o: any) => {
                      const _seller = _o.payload.toJSON();
                      _seller.$key = _o.key;
                      _sellers.push(_seller);
                    });
                    this.allsellers = _sellers;
                    //this.allsellers = allsellersresponse;
                    resolve(_sellers);
                }, (err) => {
                    console.log('Error retrieving all sellers.' + err);
                    reject(err);
                });
            } else {
                resolve(this.allsellers);
            }
        });        
        return p;
    }

    getLocations(term: string = null): Observable<string[]> {
        this.locations = this.af.list('/locations', ref => ref.orderByChild('title')).snapshotChanges()
                            .pipe(map(actions => actions.map(this.documentToLocationObject)))
        /*if (term) {
            items = items.filter(x => x.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }*/
        return this.locations
    }

    setBusinessBankInfo(razorpayinfo: any): Promise<any> {
        console.log('setBusinessBankInfo...');
        const setBusinessBankInfoPromise = new Promise<any>((resolve, reject) => {
            const addSellerBankAccountCallable = this.functions.httpsCallable('addSellerBankAccount');
            addSellerBankAccountCallable(razorpayinfo).subscribe(async res => {
                console.log(res);
                resolve(res);
            }, (error) => {
                reject(error);
            });
        });

        return setBusinessBankInfoPromise;
    }

    getBusinessBankInfo(razorpayinfo: any): Promise<any> {
        console.log('getBusinessBankInfo...');
        const getBusinessBankInfoPromise = new Promise<any>((resolve, reject) => {
            const getSellerBankAccountCallable = this.functions.httpsCallable('getSellerBankAccount');
            getSellerBankAccountCallable(razorpayinfo).subscribe(async res => {
                console.log(res);
                resolve(res);
            }, (error) => {
                reject(error);
            });
        });

        return getBusinessBankInfoPromise;
    }

    checkShortNameAvailability(_sellerid: string, newshortname: string): Promise<boolean>{
        console.log('getBusinessBankInfo...');
        const checkShortNameAvailabilityPromise = new Promise<boolean>((resolve, reject) => {
            this.af.list('/shortnames/seller/' + newshortname).valueChanges().subscribe((res: any[]) => {
                if (res.length == 0) {
                    resolve(true); // no one has taken
                } else {
                    if (res[0].sellerid == _sellerid) {
                        resolve(false); // allready used by current seller only.
                    } else {
                        resolve(true)
                    }    
                }
            }, (err) => {
                console.log('Error retrieving shortname for seller' + _sellerid + err);
                reject(err);
            });
        });

        return checkShortNameAvailabilityPromise;
    }

    saveShortName(_seller: any, newshortname: string, oldshortname: string): Promise<boolean>{
        console.log('saveShortName...');
        const saveShortNamePromise = new Promise<any>((resolve, reject) => {
            if ((oldshortname != newshortname) && (oldshortname != '')){
                // cleanup the old shortname
                this.af.list('/shortnames/seller/' + oldshortname).remove();
            }            
            this.af.object('/shortnames/seller/' + newshortname)
                .update({
                    sellerId: _seller.sellerId,
                    storeName: _seller.storeName
                })
                .then((res) => {
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
        });

        return saveShortNamePromise;
    }
}
